import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'wowjs/css/libs/animate.css';
import WOW from 'wowjs';
import about from '../assets/Images/about.jpg'

class AboutSection extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold  text-uppercase" style={{ fontSize: '3rem', color:'#66bc40'}}>A propos de nous</h5>
                <h1 className="mb-0">Bienvenue à Connect B2B Evolution</h1>
              </div>
              <p className="mb-4" style={{ fontSize: '1.8rem' , textAlign:'justify' }}>
                Connect B2B Evolution est bien plus qu'un simple événement de réseautage ; c'est un club dynamique d'entrepreneurs déterminés à explorer de nouveaux marchés, à développer leurs activités et à organiser des initiatives innovantes dans le domaine de l'entrepreneuriat et des investissements. Nous avons pour mission de créer un environnement stimulant et collaboratif où chaque membre peut trouver des opportunités significatives et faire progresser ses ambitions professionnelles.
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3" style={{ fontSize: '1.8rem' }}>
                    <FontAwesomeIcon icon={faCheck} className="text-primary me-3" />
                    Réseautage de Qualité
                  </h5>
                  <h5 className="mb-3" style={{ fontSize: '1.8rem' }}>
                    <FontAwesomeIcon icon={faCheck} className="text-primary me-3" />
                    Opportunités de Collaboration
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3" style={{ fontSize: '1.8rem' }}>
                    <FontAwesomeIcon icon={faCheck} className="text-primary me-3" />
                    24/7 Support numériques
                  </h5>
                  <h5 className="mb-3" style={{ fontSize: '1.8rem' }}>
                    <FontAwesomeIcon icon={faCheck} className="text-primary me-3" />
                    Développement des Activités
                  </h5>
                </div>
              </div>
              <p className="mb-4" style={{ fontSize: '1.8rem', textAlign:'justify' }}>
                Nous croyons fermement que l'innovation et le succès entrepreneurial reposent sur la collaboration et l'échange d'idées. Connect Evolution a été conçu pour réunir des entrepreneurs passionnés, des professionnels ambitieux et des étudiants prometteurs, formant ainsi une communauté dynamique et inspirante. En connectant des individus aux perspectives et expériences variées, nous visons à catalyser des discussions enrichissantes, à inspirer des initiatives audacieuses et à ouvrir de nouveaux horizons.
              </p>
            </div>
            <div className="col-lg-5" style={{ minHeight: '500px' }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={about}
                  alt="About"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutSection;
