import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

const HeaderBottom = ({ toggleMenu, isMenuOpen, renderNavigation }) => {
  return (
    <div className="header-bottom">
      <div className="container">
        <div className="nav-outer clearfix" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding:'0.5rem' }}>
          <nav className="main-menu" style={{ paddingRight: '20px' }}>
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" onClick={toggleMenu}>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className={`navbar-collapse collapse clearfix ${isMenuOpen ? 'show' : ''}`}>
              {renderNavigation()}
            </div>
          </nav>
          <div style={{ marginLeft: 'auto' }}>
            <Link to="/Adhesion" className="btn btn-primary py-2 px-4 theme-btn"
              style={{
                padding: '10px 20px',
                fontSize: '18px',
              }}
            >
              Adhérer
            </Link>
          </div>
          <div class="quote-btn"> <a href="/Adhesion" class="theme-btn quote-btn">Devenir membre</a> </div>

        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
