import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Typography, Box, Button, CircularProgress, Container, Divider, TextField, Grid, Alert } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Importation du style


const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { eventId } = useParams();

  useEffect(() => {
    fetch(`https://api.connect-evolution.com/api/event/view/${eventId}`)
      .then((response) => response.json())
      .then((data) => setEvent(data))
      .catch((error) => console.error('Error fetching event:', error));
  }, [eventId]);

  const splitDescription = (description, pattern = 3) => {
    const sentences = description.split('.');
    const paragraphs = [];
    for (let i = 0; i < sentences.length; i += pattern) {
      const chunk = sentences.slice(i, i + pattern).join('. ') + '.';
      paragraphs.push(chunk);
    }
    return paragraphs;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const participantData = {
      eventid: eventId, // ID de l'événement
      nom: nom,
      email: email,
      telephone: telephone,
    };

    // Debug: afficher les données du participant
    console.log('Participant Data:', participantData);

    fetch('https://api.connect-evolution.com/api/event/participant', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
      body: JSON.stringify(participantData),
   })
   .then((response) => {
      console.log('Response Status:', response.status); // Affiche le statut de la réponse
      return response.json().then((data) => {
         console.log('Response Data:', data); // Affiche les données de la réponse
         if (response.ok) {
            setSuccessMessage('Inscription réussie!');
            setNom('');
            setEmail('');
            setTelephone('');
            window.location.reload();
         } else {
            throw new Error(data.message || 'Erreur lors de l\'enregistrement du participant');
         }
      }).catch(err => {
         console.error('Erreur de parsing JSON:', err); // Pour attraper les erreurs de parsing JSON
         setErrorMessage('Erreur de traitement de la réponse.'); // Message d'erreur générique
      });
   })
   .catch((error) => {
      console.error('Error during participant registration:', error);
      setErrorMessage('Erreur lors de l\'enregistrement. Veuillez réessayer.');
   });
   
      
  };

  if (!event) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  const paragraphs = splitDescription(event.description || 'Description non disponible', 3); // Exemple : 3 phrases par paragraphe

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.5rem' }}>
          {event.nom || 'Nom non spécifié'}
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem' }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
            {event.lieu || 'Lieu non spécifié'}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem' }}>
            <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />
            {event.datedebut
              ? new Date(event.datedebut).toLocaleDateString('fr-FR', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })
              : 'Date non spécifiée'}
          </Typography>
        </Stack>
      </Box>

      <Box component="img" src={`https://api.connect-evolution.com/${event.image}`} alt={event.nom} sx={{ width: '100%', height: '40vh', borderRadius: 2 }} />

      <Box sx={{ mt: 4 }}>
        {paragraphs.map((paragraph, index) => (
          <Typography key={index} variant="body1" paragraph sx={{ fontSize: '1.7rem' }}>
            {paragraph.trim()}
          </Typography>
        ))}
      </Box>
      <Divider sx={{ my: 4 }} />

      {/* Formulaire d'inscription */}
      <Box component="form" noValidate autoComplete="off" sx={{ mt: 4 }} onSubmit={handleSubmit}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: '1.8rem' }}>
          S'enregistrer pour l'événement
        </Typography>

        {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
        {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="nom"
              label="Nom"
              name="nom"
              variant="outlined"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              sx={{ fontSize: '1.1rem' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ fontSize: '1.1rem' }}
            />
          </Grid>
          <Grid item xs={12}>
          <Grid item xs={12}>
            <PhoneInput
              defaultCountry="FR" // Code du pays par défaut
              value={telephone}
              onChange={setTelephone}
              placeholder="Numéro de téléphone"
              international
              id="telephone"
              label="Téléphone"
              name="telephone"
              variant="outlined"
              type="tel"
              sx={{ fontSize: '1.1rem' }}
              countryCallingCodeEditable={false} // Optionnel : pour désactiver la modification du code pays
              style={{ width: '1rem', fontSize: '1.1rem' }}
            />
          </Grid>
            <TextField
              required
              fullWidth
              id="telephone"
              label="Téléphone"
              name="telephone"
              variant="outlined"
              type="tel"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              sx={{ fontSize: '1.1rem' }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, fontSize: '1.1rem' }}
        >
          S'enregistrer
        </Button>
      </Box>
    </Container>
  );
};

export default EventDetails;
