import React from 'react';
import Callout from './Callout';

const CalloutsSection = () => {
  return (
    <div className="callouts-wrapper">
      <div className="container">
        <div className="title">
          <h2 style={{ fontSize: '3rem'}}> Bienvenue à <span>Connect B2B Evolution</span></h2>
          <span className="title-border-light">
            <i className="fa fa-area-chart" aria-hidden="true"></i>
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <Callout
            iconClass="lnr lnr-home"
            title="Evenements de réseautage"
            description="Nous organisons régulièrement des brunchs et des rencontres B to B"
          />
          <Callout
            iconClass="lnr lnr-chart-bars"
            title="Ateliers et Séminaires"
            description="Des séminaires qui visent le développement de compétences de nos membres"
          />
          <Callout
            iconClass="lnr lnr-user"
            title=" Mentorat et Coaching"
            description="Nos membres bénéficient de programmes de mentorat et de coaching personnalisés."
          />
        </div>
      </div>
    </div>
  );
};

export default CalloutsSection;
