import React from 'react';

import Logo from '../assets/Images/logo.png';
import { Link } from 'react-router-dom';


const HeaderMain = () => {
  return (
    <div className="header-main">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="logo">
              <Link to='/'>
                <img src={Logo} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-sm-12 col-md-9">
            <div className="header-section">
              <ul>
                <li>
                  <div className="box-left">
                    <span className="fa fa-phone"></span>
                  </div>
                  <div className="box-right">
                    <span>Contactez-nous </span> 51 000 000
                  </div>
                </li>
                <li>
                  <div className="box-left">
                    <span className="fa fa-home"></span>
                  </div>
                  <div className="box-right">
                    <span>Nos locaux</span> 300 Tunis, Tunisia
                  </div>
                </li>
                <li>
                  <div className="box-left">
                    <span className="fa fa-clock-o"></span>
                  </div>
                  <div className="box-right">
                    <span>Heures d'ouvertures</span> Lun - Ven : 9:30 - 5:30 
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMain;
