import React from 'react';

import BreadcrumbWrapper from '../Components/BreadcrumbWrapper ';
import TopBar from '../Components/TopBar';
import HeaderMain from '../Components/HeaderMain';
import ResponsiveNavbar from '../Components/NavBar';
// eslint-disable-next-line no-unused-vars
import OurTeam from '../Components/OurTeam';
import FaqAndTestimonials from '../Components/FaqAndTestimonials';
import Footer from '../Components/Footer';
import AboutSection from '../Components/AboutSection1';
import ContactForm from '../Components/ContactForm';



const About = () => {
    return (
        <>
        <header className="theme-header headerfirst" data-spy="affix" data-offset-top="197">
            <TopBar />
            <HeaderMain />
            <ResponsiveNavbar />
        </header>
        <BreadcrumbWrapper />
        <AboutSection />
        <FaqAndTestimonials />
        <ContactForm />
        <Footer />
        </>

    )
}

export default About;