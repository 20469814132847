import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../App.css'; // Assurez-vous que ce fichier CSS est correctement configuré pour les styles

import aboutBackground from '../assets/Images/carousel-1.jpg';
import adhesionBackground from '../assets/Images/checklist.jpg';
import blogBackground from '../assets/Images/BlogHeader.jpg';
import eventsBackground from '../assets/Images/event.jpg';

const BreadcrumbWrapper = () => {
  const location = useLocation();
  const path = location.pathname;

  // Déterminez le titre et l'image de fond en fonction du chemin
  const getTitleAndImage = (path) => {
    if (path.startsWith('/Apropos')) {
      return { title: 'A propos de Nous', backgroundImage: `url(${aboutBackground})` };
    }
    if (path.startsWith('/Adhesion') || path.startsWith('/DemandeAdhesion')) {
      return { title: 'Adhesion', backgroundImage: `url(${adhesionBackground})` };
    }
    if (path.startsWith('/Blog')) {
      return { title: 'Blog', backgroundImage: `url(${blogBackground})` };
    }
    if (path.startsWith('/Evenement')) {
      return { title: 'Evenements', backgroundImage: `url(${eventsBackground})` };
    }
    return { title: 'Page Not Found', backgroundImage: 'url(/images/default-background.jpg)' };
  };

  const { title, backgroundImage } = getTitleAndImage(path);

  return (
  <div className="breadcrumb-wrapper" style={{ backgroundImage }}>
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <div className="title">
            <h1>{title}</h1>
          </div>
          <ol className="breadcrumb" style={{ display: 'inline-block', padding: '0', margin: '0', fontSize: '1.5rem' }}>
            <li style={{ display: 'inline', marginRight: '10px', fontSize: '2rem' }}>
              <Link to="/">Accueil</Link>
            </li>
            <li className="active" style={{ display: 'inline', fontSize: '2rem' }}>
              {title}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  );
};

export default BreadcrumbWrapper;
