import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';




import Home from './Pages/Home';
import About from './Pages/About';
import Adhesion from './Pages/Adhesion';
import Blog from './Pages/Blog';
import Evenement from './Pages/Evenement';
import DemandeAdhesion from './Pages/DemandeAdhesion';
import SingleEvenement from './Pages/SingleEvent';
import SingleBlog from './Pages/SingleBlog';

// Créer un thème
const theme = createTheme({
  spacing: 8, // Définit l'espacement de base
  // Ajoutez d'autres configurations de thème ici
});


function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Apropos" element={<About />} />
        <Route path="/Adhesion" element={<Adhesion />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Evenement" element={<Evenement />} />
        <Route path="/DemandeAdhesion/:planId" element={<DemandeAdhesion />} />
        <Route path="/Evenement/:eventId" element={<SingleEvenement />} />
        <Route path="/Blog/:blogId" element={<SingleBlog />} />
        
      </Routes>
    </div>
    </ThemeProvider>
  );
}

export default App;
