import React from 'react';

import BreadcrumbWrapper from '../Components/BreadcrumbWrapper ';
import TopBar from '../Components/TopBar';
import HeaderMain from '../Components/HeaderMain';
import ResponsiveNavbar from '../Components/NavBar';
import Footer from '../Components/Footer';
import EventDetails from '../Components/EventDetails';

const SingleEvenement = () => {
    return (
        <>
        <header className="theme-header headerfirst" data-spy="affix" data-offset-top="197">
            <TopBar />
            <HeaderMain />
            <ResponsiveNavbar />
        </header>
        <BreadcrumbWrapper />
        <EventDetails />
        <Footer />
        </>

    )
}

export default SingleEvenement;