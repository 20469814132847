import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Footer() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Email:', email); // Debugging
    console.log('Name:', name);   // Debugging
  
    try {
      // Envoyer la demande au serveur pour l'inscription
      await axios.post('https://api.connect-evolution.com/api/send-welcome-email', {
        email,
        name
      });
      alert('Inscription réussie ! Un email de bienvenue a été envoyé.');
      window.location.reload(); // Recharger la page

    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email de bienvenue:', error);
      alert('Une erreur est survenue lors de la soumission du formulaire. Veuillez réessayer plus tard.');
    }
  };

  return (
    <footer id="footer-area">
      <div className="footer-top footer-padding">
        <div className="container">
          <div className="row">
            {/* Connect B2B Evolution Widget */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="widget widget_text">
                <h3 className="widget-title">Connect B2B Evolution</h3>
                <p>
                  Connect B2B Evolution est votre passerelle vers des opportunités commerciales stratégiques. Rejoignez notre réseau pour dynamiser vos projets et collaborer avec des partenaires d'exception.
                </p>
              </div>
            </div>
            {/* Liens Utiles Widget */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              {/* Ajoutez du contenu si nécessaire */}
            </div>
            {/* Contactez-Nous Widget */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="widget">
                <h3 className="widget-title">Contactez-Nous</h3>
                <p>
                  Pour toute question ou demande d'information, n'hésitez pas à nous contacter. Notre équipe est là pour vous accompagner dans votre évolution.
                </p>
                <Link to='/Apropos' className="button contact-btn">Contactez-Nous</Link>
              </div>
            </div>
            {/* Inscription à la Newsletter Widget */}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="widget">
                <h3 className="widget-title">Inscription à la Newsletter</h3>
                <form className="footer-newsletter" onSubmit={handleSubmit}>
                  <div className="input-email">
                    <input
                      placeholder="Entrez votre nom"
                      name="name"
                      id="name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="input-email">
                    <input
                      placeholder="Entrez votre email"
                      name="email"
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <i className="icofont icofont-send-mail"></i>
                  </div>
                  <button type="submit" className="btn">S'inscrire</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom fix">
        <div className="container bb-top foo-padding">
          <div className="row">
            <div className="col-sm-12">
              <div className="social-links">
                <ul>
                  <li className="facebook"><Link to="#" className="fa fa-facebook"></Link></li>
                  <li className="twitter"><Link to="#" className="fa fa-twitter"></Link></li>
                  <li className="linkedin"><Link to="#" className="fa fa-linkedin"></Link></li>
                  <li className="youtube"><Link to="#" className="fa fa-youtube"></Link></li>
                </ul>
              </div>
              <p>&copy; Copyright {new Date().getFullYear()}, Connect B2B Evolution | Tous droits réservés.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
