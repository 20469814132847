import React from 'react';

const Callout = ({ iconClass, title, description }) => {
  return (
    <div className="col-sm-4">
      <div className="callouts">
        <span className={iconClass}></span>
        <h3>
          <a href="javascriptvoid">{title}</a>
        </h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Callout;
