import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.connect-evolution.com/api/contact/sendMessage', formData);
      alert(response.data.message);
    } catch (error) {
      alert('Échec de l\'envoi du message. Veuillez réessayer.');
    }
  };

  return (
    <div className="inner-page-wrapper contact-us">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h2>Get in Touch</h2>
            <div className="row">
              <div className="col-sm-4">
                <div className="address">
                  <div className="icon-border">
                    <div className="icon"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                  </div>
                  <p>
                    <strong>Locaux</strong> <br />
                    Place<br />
                    2080, Tunis, Tunisia
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="address">
                  <div className="icon-border">
                    <div className="icon"><i className="fa fa-phone" aria-hidden="true"></i></div>
                  </div>
                  <p>
                    <strong>Phone</strong><br />
                    51 000 000<br />
                    51 000 000
                  </p>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="address">
                  <div className="icon-border">
                    <div className="icon"><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                  </div>
                  <p>
                    <strong>Email</strong> <br />
                    <a href="mailto:support@sbtechnosoft.com">support@ConnectEvolution.com</a><br />
                    <a href="mailto:service@sbtechnosoft.com">service@ConnectEvolution.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <h2>Laissez un Message</h2>
            <div className="forms">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  required
                  placeholder="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  required
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <textarea
                  placeholder="Saisissez votre message ici"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <button type="submit" className="btn">Envoyer</button>
              </form>
            </div>
          </div>
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102239.59408190833!2d10.060876654451102!3d36.79485453028062!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd337f5e7ef543%3A0xd671924e714a0275!2sTunis%2C%20Tunisie!5e0!3m2!1sfr!2scg!4v1723888103075!5m2!1sfr!2scg"
            width="100%"
            height="450"
            style={{ border: 0, margin: 20 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
