import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Avatar } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useParams } from 'react-router-dom';

function CommentSection() {
  const [comments, setComments] = useState([]);
  const { blogId } = useParams();

  useEffect(() => {
    fetch(`https://api.connect-evolution.com/api/blog/${blogId}/comments`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.comments)) {
          setComments(data.comments);
        } else {
          console.error('Les commentaires ne sont pas dans un format attendu:', data);
          setComments([]);
        }
      })
      .catch((error) => console.error('Erreur lors de la récupération des commentaires:', error));
  }, [blogId]);

  const formatDate = (dateString) => {
    if (!dateString) {
      return 'Date inconnue';
    }

    const [datePart, timePart] = dateString.split(' ');
    const formattedDateString = `${datePart}T${timePart}`;
    const date = new Date(formattedDateString);

    if (isNaN(date.getTime())) {
      return 'Date invalide';
    }

    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  };

  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: '1.8rem' }}>
        Commentaires ({comments.length})
      </Typography>

      <List>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <ListItem alignItems="flex-start" key={index}>
              <Avatar sx={{ bgcolor: deepPurple[500], marginRight: 2 }}>
                {comment.nom ? comment.nom.charAt(0).toUpperCase() : 'U'}
              </Avatar>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontSize: '1.7rem' }}>
                    {comment.comment}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" sx={{ fontSize: '1.2rem', color: 'text.secondary' }}>
                    Posté le {formatDate(comment.date_comment)}
                  </Typography>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1" sx={{ marginTop: 2, fontSize: '1rem' }}>
            Aucun commentaire pour le moment.
          </Typography>
        )}
      </List>
    </Box>
  );
}

export default CommentSection;
