import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Pagination,
  CircularProgress,
  Stack,
  Container,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faArrowLeft, faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import WOW from 'wowjs';
import 'animate.css';

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://api.connect-evolution.com/api/event', {
          params: {
            page: currentPage,
            limit: 9
          }
        });
        console.log('Réponse de l\'API:', response.data); // Vérifiez la structure ici
        setEvents(response.data || []); // Utilisez directement la réponse comme tableau d'événements
        // Vous devez mettre en place une logique pour calculer `totalPages` en fonction des données reçues
        setTotalPages(Math.ceil(response.data.length / 9)); // Exemple pour 9 événements par page
      } catch (error) {
        setError('Erreur lors du chargement des événements');
        console.error('Erreur lors du chargement des événements', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  

  
  if (loading) {
    return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Container>
    );
  }
   if (error) return <div>{error}</div>;

  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        {/* Sidebar Start */}
        <div className="row g-5">
        {/* Search Form Start */}
        <div className="col-12 mb-5 wow slideInUp" data-wow-delay="0.1s">
          <div className="input-group">
            <input
              type="text"
              className="form-control p-3"
              placeholder="Mots clés"
            />
            <button className="btn btn-primary px-4">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        {/* Search Form End */}

        {/* Event list Start */}
        <div className="col-12">
          <Grid container spacing={3}>
            {events.length > 0 ? (
              events.map((event) => (
                <Grid item xs={12} sm={6} md={4} key={event.id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardMedia
                      component="img"
                      height="200"
                      image={`https://api.connect-evolution.com/${event.image}`}
                      alt={event.nom}
                      sx={{ objectFit: 'cover' }}
                    />
                    <CardContent sx={{ flex: 1 }}>
                      <Typography variant="h2" component="div">
                        {event.nom || 'Nom non spécifié'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {event.description
                          ? `${event.description.slice(0, 100)}...`
                          : 'Description non disponible'}
                      </Typography>
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Typography variant="body2" color="text.primary">
                          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: 8 }} />
                          {event.lieu || 'Lieu non spécifié'}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 8 }} />
                          {event.datedebut
                            ? new Date(event.datedebut).toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })
                            : 'Date non spécifiée'}
                        </Typography>
                      </Stack>
                    </CardContent>
                    <Box sx={{ p: 2 }}>
                        <Link to={`/Evenement/${event.id}`}>
                          <Button variant="outlined" color="primary">
                            Voir les détails
                          </Button>
                        </Link>
                    </Box>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>Aucun événement disponible</Typography>
            )}
          </Grid>
        </div>
        {/* Event list End */}
      </div>

      </div>
    </div>
  );
};

export default EventList;
