import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import HeaderBottom from '../Components/HeaderB';
import StickyHeader from '../Components/HeaderS';

const ResponsiveNavbar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getLinkClass = (path) => {
    return location.pathname === path ? 'current' : '';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderNavigation = () => (
    <ul className="navigation clearfix">
      <li className={getLinkClass('/')}>
        <Link to="/">Accueil</Link>
      </li>
      <li className={getLinkClass('/Apropos')}>
        <Link to="/Apropos">À propos</Link>
      </li>
      <li className={getLinkClass('/Adhesion')}>
        <Link to="/Adhesion">Adhésion</Link>
      </li>
      <li className={getLinkClass('/Evenement')}>
        <Link to="/Evenement">Événements</Link>
      </li>
      <li className={getLinkClass('/Blog')}>
        <Link to="/Blog">Blog</Link>
      </li>
    </ul>
  );

  return (
    <>
      <HeaderBottom 
        toggleMenu={toggleMenu} 
        isMenuOpen={isMenuOpen} 
        renderNavigation={renderNavigation} 
      />
      <StickyHeader 
        toggleMenu={toggleMenu} 
        isMenuOpen={isMenuOpen} 
        renderNavigation={renderNavigation} 
      />
    </>
  );
};

export default ResponsiveNavbar;
