import React from 'react';
import PropTypes from 'prop-types';

const Vide = ({ height }) => {
  return <div style={{ height }} />;
};

Vide.propTypes = {
  height: PropTypes.string.isRequired, // Exige une valeur de hauteur sous forme de chaîne
};

export default Vide;
