import React from 'react';
import '../App.css';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fontSize } from '@mui/system';


const HomeSlider = () => {
  return (
    <div className="home-slider-area">
    <Carousel id="welcome-slide-carousel" interval={3000} fade>
    <Carousel.Item className="slide-1">
        <div className="single-slide-item">
        <div className="single-slide-item-table">
            <div className="single-slide-item-table-cell">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <h2>Connect B2B Evolution</h2>
                    <p style={{ fontSize: '2rem'}}> Tisser des liens, Partager des idées, Inspirer l'avenir"</p>
                    <Link className="slide-btn" to='/Adhesion'>Devenir Membre</Link>
                    <Link className="slide-btn" to='/Apropos'>En savoir +</Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </Carousel.Item>
    <Carousel.Item className="slide-2">
        <div className="single-slide-item">
        <div className="single-slide-item-table">
            <div className="single-slide-item-table-cell">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <h2>Connect B2B Evolution</h2>
                    <p style={{ fontSize: '2rem'}}>Tisser des liens, Partager des idées, Inspirer l'avenir"</p>
                    <Link className="slide-btn" to='/Adhesion'>Devenir Membre</Link>
                    <Link className="slide-btn" to='/Apropos'>En savoir +</Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </Carousel.Item>
    <Carousel.Item className="slide-3">
        <div className="single-slide-item">
        <div className="single-slide-item-table">
            <div className="single-slide-item-table-cell">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <h2>Connect B2B Evolution</h2>
                    <p style={{ fontSize: '2rem'}}>Tisser des liens, Partager des idées, Inspirer l'avenir"</p>
                    <Link className="slide-btn" to='/Adhesion'>Devenir Membre</Link>
                    <Link className="slide-btn" to='/Apropos'>En savoir +</Link>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </Carousel.Item>
    </Carousel>
</div>
  );
};

export default HomeSlider;
