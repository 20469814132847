import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Divider, TextField, Button, Grid } from '@mui/material';
import CommentSection from './CommentSection';

const BlogDetails = () => {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    fetch(`https://api.connect-evolution.com/api/blog/view/${blogId}`)
      .then((response) => response.json())
      .then((data) => setBlog(data))
      .catch((error) => console.error('Error fetching blog:', error));})

  const handleCommentSubmit = () => {
    if (nom.trim() !== '' && email.trim() !== '' && newComment.trim() !== '') {
      const commentData = {
        nom: nom.trim(),
        email: email.trim(),
        comment: newComment.trim(),
        date_Comment: new Date().toISOString(), // Générer la date actuelle
        blog_Id: blogId // Inclure l'ID du blog
      };

      fetch(`https://api.connect-evolution.com/api/blog/${blogId}/Addcomment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'Comment successfully added') {
            console.log('Commentaire ajouté avec succès');
          } else {
            console.error('Erreur lors de l\'ajout du commentaire:', data.errors || data.message);
          }
        })
        .catch((error) => console.error('Error posting comment:', error));
      
      setNom('');
      setEmail('');
      setNewComment('');
    }
  };

  const splitDescription = (description, pattern = 3) => {
    const sentences = description.split('.');
    const paragraphs = [];
    for (let i = 0; i < sentences.length; i += pattern) {
      const chunk = sentences.slice(i, i + pattern).join('. ') + '.';
      paragraphs.push(chunk);
    }
    return paragraphs;
  };

  if (!blog) {
    return <Typography>Chargement...</Typography>;
  }

  const paragraphs = splitDescription(blog.contenu || 'Contenu non disponible');

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', fontSize: '2.5rem' }}>
          {blog.titre || 'Titre non spécifié'}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '1.2rem' }}>
          Publié le {new Date(blog.date).toLocaleDateString('fr-FR', { day: '2-digit', month: 'short', year: 'numeric' })}
        </Typography>
      </Box>

      <Box component="img" src={`https://api.connect-evolution.com/${blog.couverture}`} alt={blog.titre} sx={{ width: '100%', height: '40vh', borderRadius: 2 }} />

      <Box sx={{ mt: 4 }}>
        {paragraphs.map((paragraph, index) => (
          <Typography key={index} variant="body1" paragraph sx={{ fontSize: '1.7rem' }}>
            {paragraph}
          </Typography>
        ))}
      </Box>

      <Divider sx={{ my: 4 }} />

      {/* Formulaire de commentaire */}
      <Box component="form" noValidate autoComplete="off" sx={{ mt: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: '1.8rem' }}>
          Ajouter un commentaire
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="nom"
              label="Votre nom"
              name="nom"
              variant="outlined"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              sx={{ fontSize: '1.1rem' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Votre email"
              name="email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ fontSize: '1.1rem' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="newComment"
              label="Votre commentaire"
              name="newComment"
              variant="outlined"
              multiline
              rows={4}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              sx={{ fontSize: '1.1rem' }}
            />
          </Grid>
        </Grid>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, fontSize: '1.1rem' }}
          onClick={handleCommentSubmit}
        >
          Ajouter
        </Button>
      </Box>
      
      {/* Section des commentaires */}
      <CommentSection />
    </Container>
  );
};

export default BlogDetails;
