/* eslint-disable no-unused-vars */
import React from 'react';

import TopBar from '../Components/TopBar';
import HeaderMain from '../Components/HeaderMain';
import HeaderCarousel from '../Components/HomeSlidder';
import CalloutsSection from '../Components/CalloutSection';
import AboutSection from '../Components/AboutSection';
import FreeConsultation from '../Components/FreeConsultation';
import FaqAndTestimonials from '../Components/FaqAndTestimonials';
import Counters from '../Components/Counters';
// eslint-disable-next-line no-unused-vars
import OurTeam from '../Components/OurTeam';
import OurBlog from '../Components/OurBlog';
import Footer from '../Components/Footer';
import ResponsiveNavbar from '../Components/NavBar';
import MembershipPlans from '../Components/MembershipPlans';
import Vide from '../Components/vide';




const Home = () => {
    return (
        <>
        <header className="theme-header headerfirst "data-spy="affix" data-offset-top="197" >
            <TopBar />
            <HeaderMain />
            <ResponsiveNavbar />
        </header>
        <HeaderCarousel />
        <CalloutsSection />
        <AboutSection />
        <FreeConsultation />
        <MembershipPlans  />
        <Counters />
        <FaqAndTestimonials />
        <Footer />
        </>
    )

}

export default Home;