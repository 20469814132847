import React from 'react';
import { Link } from 'react-router-dom';

const FreeConsultation = () => {
  return (


  <div className="free-consultation">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-8">
          <div className="free-text">
            <h3>Contactez nous pour une consultation gratuite</h3>
            <p>
              Vous etes entrepreneurs, étudiant, CEO, votre entreprise bat de l'aile et vous voulez retrouver la croissance ? Contactez Connect B2B Evolution 
            </p>
          </div>
        </div>
        <div className="col-md-6 text-right col-sm-4">
          <Link to='/Apropos' className="btn">Contact</Link>
        </div>
      </div>
    </div>
  </div>
  );
};

export default FreeConsultation;
