import React, { useEffect, useState } from 'react';
import WOW from 'wowjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Switch,SimpleGrid,FormLabel,FormControl } from '@chakra-ui/react'

export default function MembershipPlans() {
  const [plans, setPlans] = useState([]);
  const [isAnnuel, setIsAnnuel] = useState(false);
  const [avantagesGlobaux, setAvantagesGlobaux] = useState([
    'Accès à la plateforme',
    'Support client',
    'Mises à jour régulières',
    'Fonctionnalités premium',
  ]);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();

    const fetchPlans = async () => {
      try {
        const response = await axios.get('https://api.connect-evolution.com/api/plan');
        setPlans(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des plans:', error);
        alert('Une erreur est survenue lors de la récupération des plans. Veuillez réessayer plus tard.');
      }
    };

    fetchPlans();
  }, []);

  const handleSwitch = () => {
    setIsAnnuel(!isAnnuel);
  };

  return (
    <div className="plan container-fluid py-5 wow animate__animated animate__fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" >
          <h1 className="fw-bold  text-uppercase" style={{ fontSize: '3rem', marginTop: '1rem', color:'#66bc40' }}>
            Plans d'adhésion
          </h1>
          <h1 className="mb-0">Nous offrons différents plans d'adhésion pour nos membres</h1>
        </div>

        {/* Switch pour le prix mensuel/annuel */}
        <div className="text-center mb-4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontSize: '2rem', marginRight: '10px' }}>
            {isAnnuel ? 'Prix Annuel' : 'Prix Mensuel'}
          </span>
          <label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" checked={isAnnuel} onChange={handleSwitch} />
            <span className="slider"></span>
          </label>
        </div>



        <div id="pricing-table" className="clear" style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
          {plans.map((plan, index) => (
            <div className="plan" key={index} id={plan.id === "Professional" ? "most-popular" : ""} style={{ width: '28rem', border: '1px solid #ddd', borderRadius: '8px' }}>
              <h3>{plan.nom}<span>{isAnnuel ? (plan.prixAnnuel || plan.prix) : plan.prix}$</span></h3>
              <a className="signup" href="#">Adherer</a>
              <ul>
                {avantagesGlobaux.map((avantage, index) => (
                  <li key={index} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="icon" style={{ marginRight: '8px' }}>
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <p style={{ margin: 0 }}>{avantage}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
