import React from 'react';

import BreadcrumbWrapper from '../Components/BreadcrumbWrapper ';
import TopBar from '../Components/TopBar';
import HeaderMain from '../Components/HeaderMain';
import ResponsiveNavbar from '../Components/NavBar';
import Footer from '../Components/Footer';
import BlogSection from '../Components/Blogsection';


const Blog = () => {
    return (
        <>
        <header className="theme-header headerfirst" data-spy="affix" data-offset-top="197">
            <TopBar />
            <HeaderMain />
            <ResponsiveNavbar />
        </header>
        <BreadcrumbWrapper />
        <BlogSection />
        <Footer />
        </>

    )
}

export default Blog;