import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa'; // Importation d'une icône de Font Awesome
import 'bootstrap/dist/css/bootstrap.min.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Importation du style

// Définir le schéma de validation avec Yup
const schema = yup.object().shape({
  Nom: yup.string().required('Le Nom est requis').min(2, 'Le Nom doit avoir au moins 2 caractères').max(255, 'Le Nom doit avoir au maximum 255 caractères'),
  Prenom: yup.string().required('Le Prénom est requis').min(2, 'Le Prénom doit avoir au moins 2 caractères').max(255, 'Le Prénom doit avoir au maximum 255 caractères'),
  Email: yup.string().email('Email invalide').required('Email est requis').min(2, 'Email doit avoir au moins 2 caractères').max(255, 'Email doit avoir au maximum 255 caractères'),
  Telephone: yup.string().required('Téléphone est requis').min(10, 'Téléphone doit avoir au moins 10 caractères').max(20, 'Téléphone doit avoir au maximum 20 caractères'),
  Profession: yup.string().required('La Profession est requise').min(2, 'La Profession doit avoir au moins 2 caractères').max(100, 'La Profession doit avoir au maximum 100 caractères'),
  Adresse: yup.string().required('Adresse est requise').min(5, 'Adresse doit avoir au moins 5 caractères').max(255, 'Adresse doit avoir au maximum 255 caractères'),
  domaine: yup.string().required('Domaine est requis').min(2, 'Domaine doit avoir au moins 2 caractères').max(255, 'Domaine doit avoir au maximum 255 caractères'),
  cv: yup.mixed().required('CV est requis'),
  Portrait: yup.mixed().required('Portrait est requis'),
  businessPlan: yup.mixed().required('Business Plan est requis'),
  Password: yup.string().required('Mot de passe est requis').min(8, 'Mot de passe doit avoir au moins 8 caractères')
});

const AdherentForm = () => {
  const { planId } = useParams(); // Récupérer planId depuis les paramètres de l'URL
  const [plan, setPlan] = useState(null);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fonction pour récupérer les données du plan
    const fetchPlan = async () => {
      try {
        const response = await axios.get(`https://api.connect-evolution.com/api/plan/${planId}`);
        setPlan(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données du plan:', error);
        alert('Erreur lors de la récupération des données du plan');
      }
    };

    fetchPlan();
  }, [planId]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append('planId', planId); // Utiliser planId récupéré depuis les paramètres
    formData.append('nom', data.Nom);
    formData.append('prenom', data.Prenom);
    formData.append('email', data.Email);
    formData.append('password', data.Password);
    formData.append('adresse', data.Adresse);
    formData.append('profession', data.Profession);
    formData.append('domaine', data.domaine);
    formData.append('telephone', data.Telephone);
    formData.append('cv', data.cv[0]);
    formData.append('Portrait', data.Portrait[0]);
    formData.append('businessPlan', data.businessPlan[0]);

    try {
      const response = await axios.post('https://api.connect-evolution.com/api/adhesion', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response.data);
      alert('Adhésion réussie');
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      alert('Erreur lors de la soumission du formulaire');
    }
  };

  if (!plan) return <p>Chargement des informations du plan...</p>;

  return (
    <Container className="my-5">
      <div className="section-title text-center mb-5">
        <h2 className="fw-bold text-primary text-uppercase">Adhésion</h2>
      </div>
      <div className="card shadow-lg border-light">
        <div className="card-body">
          <h3 className="card-title text-primary">{plan.nom}</h3>
          <p className="card-text text-muted">{plan.description}</p>
          <h4 className="text-success">Prix: €{plan.prix} / Mois</h4>
          <hr />
          {plan.avantages && plan.avantages.length > 0 ? (
            <ul className="list-group">
              {plan.avantages.map((avantage, index) => (
                <li key={index} className="list-group-item d-flex align-items-center">
                  <FaCheckCircle className="text-success me-2" />
                  {avantage}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-muted">Aucun avantage disponible pour ce plan.</p>
          )}
        </div>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} className="p-5 border rounded shadow-lg bg-light my-4">
        {errors && Object.keys(errors).length > 0 && (
          <Alert variant="danger">
            <ul className="mb-0">
              {Object.keys(errors).map((key) => (
                <li key={key}>{errors[key].message}</li>
              ))}
            </ul>
          </Alert>
        )}
        <Form.Group className="mb-4">
          <Form.Label>Nom</Form.Label>
          <Form.Control type="text" {...register('Nom')} placeholder="Entrez votre nom" />
          {errors.Nom && <Form.Text className="text-danger">{errors.Nom.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Prénom</Form.Label>
          <Form.Control type="text" {...register('Prenom')} placeholder="Entrez votre prénom" />
          {errors.Prenom && <Form.Text className="text-danger">{errors.Prenom.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" {...register('Email')} placeholder="Entrez votre email" />
          {errors.Email && <Form.Text className="text-danger">{errors.Email.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
        <Form.Label>Téléphone (inclure l'indicatif téléphonique)</Form.Label>
        <PhoneInput
            defaultCountry="FR" // Code du pays par défaut
            onChange={(value) => {
              register('Telephone').onChange({ target: { value } });
            }}
            placeholder="Numéro de téléphone"
            international
            id="telephone"
            label="Téléphone"
            name="telephone"
            style={{ width: '1rem' }}
          />
        <Form.Control 
          type="text" 
          {...register('Telephone')} 
          placeholder="+33 6 12 34 56 78" 
        />
        {errors.Telephone && <Form.Text className="text-danger">{errors.Telephone.message}</Form.Text>}
      </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Profession</Form.Label>
          <Form.Control type="text" {...register('Profession')} placeholder="Entrez votre profession" />
          {errors.Profession && <Form.Text className="text-danger">{errors.Profession.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Adresse</Form.Label>
          <Form.Control type="text" {...register('Adresse')} placeholder="Entrez votre adresse" />
          {errors.Adresse && <Form.Text className="text-danger">{errors.Adresse.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Domaine</Form.Label>
          <Form.Control type="text" {...register('domaine')} placeholder="Entrez votre domaine" />
          {errors.domaine && <Form.Text className="text-danger">{errors.domaine.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>CV</Form.Label>
          <Form.Control type="file" {...register('cv')} />
          {errors.cv && <Form.Text className="text-danger">{errors.cv.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Portrait</Form.Label>
          <Form.Control type="file" {...register('Portrait')} />
          {errors.Portrait && <Form.Text className="text-danger">{errors.Portrait.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Business Plan</Form.Label>
          <Form.Control type="file" {...register('businessPlan')} />
          {errors.businessPlan && <Form.Text className="text-danger">{errors.businessPlan.message}</Form.Text>}
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Mot de passe</Form.Label>
          <Form.Control type="password" {...register('Password')} placeholder="Entrez votre mot de passe" />
          {errors.Password && <Form.Text className="text-danger">{errors.Password.message}</Form.Text>}
        </Form.Group>
        <Button type="submit" variant="primary" className="w-100 py-3">Soumettre</Button>
      </Form>
    </Container>
  );
};

export default AdherentForm;
