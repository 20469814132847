import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Container,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import '../App.css';

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://api.connect-evolution.com/api/blog', {
          params: {
            page: currentPage,
            limit: 6,
          },
        });
        // Ajouter les nouveaux blogs sans duplication
        setBlogs(prevBlogs => [
          ...prevBlogs,
          ...response.data.blogs.filter(blog => !prevBlogs.some(existingBlog => existingBlog.id === blog.id))
        ]);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        console.error('Erreur lors du chargement des blogs:', err);
        setError('Impossible de charger les blogs');
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlogs();
  }, [currentPage]);
  

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };


  if (loading) {
    return (
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Container>
    );
}  if (error) return <div>{error}</div>;

  return (
    <>
      {/* Blog Section Begin */}
      <section className="container-fluid py-5 wow fadeInUp blog-section spad">
        <div className="container">
          <div className="row">
            {/* Grille de blogs */}
            {blogs.map(blog => (
              <div className="col-lg-4 col-md-6 mb-4" key={blog.id}>
                <div
                  className="blog-item set-bg"
                  style={{ 
                    backgroundImage: `url(https://api.connect-evolution.com/${blog.couverture})`,
                    height: '30vh', // Ajustez la hauteur ici
                    backgroundSize: 'cover', // Assurez-vous que l'image couvre tout l'élément
                    backgroundPosition: 'center', // Centrez l'image
                    position: 'relative' // Pour permettre un positionnement absolu des éléments enfants
                  }}
                >
                  <div className="bi-tag bg-gradient">{blog.categorie}</div>
                  
                  <div className="bi-text"
                    style={{
                      position: 'absolute', // Pour positionner en bas
                      bottom: 0, // Place l'élément en bas de la carte
                      width: '100%', // S'assure que le texte prend toute la largeur de la carte
                      background: 'rgba(0, 0, 0, 0.6)', // Fond semi-transparent pour rendre le texte lisible
                      color: '#fff', // Texte en blanc pour la visibilité
                      padding: '10px', // Espacement interne
                      boxSizing: 'border-box', // Pour inclure le padding dans la largeur
                    }}
                  >
                    <h1
                      style={{
                        margin: 0, // Supprime la marge autour du titre
                        fontSize: '2.5rem', // Augmente la taille de la police
                        fontWeight: 'bold', // Texte en gras pour plus d'impact
                      }}
                    >
                      <a 
                        href={`./Blog/${blog.id}`}
                        style={{
                          color: '#fff', // Assure que le lien est blanc pour la visibilité
                          textDecoration: 'none' // Supprime le soulignement par défaut du lien
                        }}
                      >
                        {blog.titre}
                      </a>
                    </h1>
                    <span style={{ fontSize: '1rem' }}>
                      <FontAwesomeIcon icon={faClock} /> {new Date(blog.date_creation).toLocaleDateString('fr-FR', { day: '2-digit', month: 'short', year: 'numeric' })}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="load-more blog-more text-center mt-4">
            <button className="primary-btn" onClick={handleLoadMore} disabled={loading || currentPage >= totalPages}>
              {loading ? 'Chargement...' : 'Load More'}
            </button>
          </div>
        </div>
      </section>
      {/* Blog Section End */}
    </>
  );
};

export default BlogSection;
