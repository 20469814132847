import React from 'react';
import { Carousel } from 'react-bootstrap';
import Temoignage from '../assets/Images/testimonials1.png'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
  } from '@mui/material';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqAndTestimonials = () => {
  return (


    <div className="home-faq-wrapper">
        <div className="container">
            <div className="title">
                <h2 style={{ fontSize: '3rem'}}>FAQ & <span>Témoignages</span></h2>
                <span className="title-border-light"><i className="fa fa-area-chart" aria-hidden="true"></i></span>
            </div>
        </div>
        <div className="container">
        <div className="row">
            <div className="col-sm-6">
                <Carousel id="myCarousel1" interval={5000}>
                    <Carousel.Item>
                    <blockquote >
                    "Connect B2B Evolution est une ressource inestimable pour notre équipe commerciale. Nous avons pu explorer de nouveaux marchés et entrer en contact avec des entreprises internationales que nous n'aurions jamais pu approcher autrement. Le retour sur investissement est excellent, et les résultats sont visibles dès les premiers mois d'utilisation."
                    <div className="author">
                        <div className="test-img">
                            <img src={Temoignage} alt="" />
                        </div>
                        <div className="client-name">
                        <h3>Luc Martin</h3>
                        <p>Responsable Marketing - Global Trade Inc.</p>
                        </div>
                        </div>
                    </blockquote>
                    </Carousel.Item>
                    <Carousel.Item>
                    <blockquote>
                    "Connect B2B Evolution a transformé notre façon de faire des affaires. Grâce à la plateforme, nous avons pu nouer des partenariats stratégiques avec des entreprises complémentaires à notre activité. Les outils de recherche sont intuitifs et nous ont permis de cibler rapidement les bons contacts. Depuis notre inscription, notre réseau s'est considérablement élargi et notre chiffre d'affaires a augmenté de 20%."
                    <div className="author">
                        <div className="test-img">
                            <img src={Temoignage} alt="" />
                        </div>
                        <div className="client-name">
                            <h3>Michal Marek</h3>
                            <p> Directeur Général - Tech Solutions</p>
                        </div>
                        </div>
                    </blockquote>
                    </Carousel.Item>
                    <Carousel.Item>
                    <blockquote>
                    "En tant que startup, il est souvent difficile de trouver des partenaires fiables. Connect B2B Evolution nous a ouvert des portes auxquelles nous n'aurions jamais eu accès autrement. Nous avons trouvé des fournisseurs de confiance et même des investisseurs intéressés par notre projet. La plateforme est facile à utiliser, et le support client est réactif et toujours prêt à aider."
                    <div className="author">
                        <div className="test-img">
                            <img src={Temoignage} alt="" />
                        </div>
                        <div className="client-name">
                        <h3> Marie Lefevre</h3>
                        <p>Fondatrice - Green Innovators</p>
                        </div>
                        </div>
                    </blockquote>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="col-sm-6">
            <Box sx={{ maxWidth: '600px', margin: '3rem ' }}>
            <Accordion sx={{ bgcolor: '#66bc40', color: 'white', '&.Mui-expanded': { bgcolor: 'white' }, marginBottom: '8px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white', '&.Mui-expanded': { color: 'black' } }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        minHeight: '40px',
                        '& .MuiAccordionSummary-content': { margin: '1px 0' },
                        '&.Mui-expanded': { color: 'black' },
                    }}
                >
                    <Typography sx={{ color: 'inherit' }}>Qu'est-ce que Connect B2B Evolution ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Connect B2B Evolution est une plateforme dédiée aux entreprises pour faciliter les échanges professionnels, la mise en réseau, et le développement commercial grâce à des outils innovants et un réseau de partenaires qualifiés.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ bgcolor: '#66bc40', color: 'white', '&.Mui-expanded': { bgcolor: 'white' }, marginBottom: '8px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white', '&.Mui-expanded': { color: 'black' } }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{
                        minHeight: '40px',
                        '& .MuiAccordionSummary-content': { margin: '1px 0' },
                        '&.Mui-expanded': { color: 'black' },
                    }}
                >
                    <Typography sx={{ color: 'inherit' }}>Comment rejoindre Connect B2B Evolution ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Pour rejoindre Connect B2B Evolution, vous pouvez vous inscrire directement sur notre site web en remplissant le formulaire d'adhésion. Vous serez ensuite contacté par notre équipe pour finaliser votre inscription et commencer à profiter des avantages de notre réseau.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={{ bgcolor: '#66bc40', color: 'white', '&.Mui-expanded': { bgcolor: 'white' } }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white', '&.Mui-expanded': { color: 'black' } }} />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    sx={{
                        minHeight: '40px',
                        '& .MuiAccordionSummary-content': { margin: '1px 0' },
                        '&.Mui-expanded': { color: 'black' },
                    }}
                >
                    <Typography sx={{ color: 'inherit' }}>Quel type d'entreprise peut rejoindre Connect B2B Evolution ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Connect B2B Evolution est ouvert à toutes les entreprises, quel que soit leur secteur d'activité. Que vous soyez une startup, une PME, ou une grande entreprise, notre plateforme vous offre les outils et le réseau nécessaires pour vous développer.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
</div>

        </div>
        </div>
    </div>


  );
};

export default FaqAndTestimonials;
