import React from 'react';
import { Link } from 'react-router-dom';

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <ul className="list-unstyled list-inline">
              <li>
                <p>
                  <i className="fa fa-envelope"></i>
                  <a href="mailto:support@sbtechnosoft.com">contact@connectevolution.com</a>
                </p>
              </li>
              <li>
                <p>
                  <i className="fa fa-phone"></i>  51 000 000
                </p>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 text-right">
            <div className="top-header-wrapper">
              <Link to='/Adhesion'>
                <i className="fa fa-user"></i> Adherer
              </Link>
              <span className="separator">|</span>
              <a href="0">
                <i className="fa fa-lock"></i> Se connecter
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
