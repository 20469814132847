import React from 'react';

const Counters = () => {
  return (
            <div className="counters">
            <div className="container">
                <div className="row">
                <div className="col-sm-3">
                    <div className="counter">
                    <div className="counter-icon-box"><i className="lnr lnr-clock"></i></div>
                    <div className="number animateNumber" data-num="5325"> <span>5325</span></div>
                    <p>Un réseau de</p>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="counter">
                    <div className="counter-icon-box"><i className="lnr lnr-users"></i></div>
                    <div className="number animateNumber" data-num="160"> <span>160</span></div>
                    <p> Projets assistés</p>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="counter">
                    <div className="counter-icon-box"><i className="lnr lnr-laptop"></i></div>
                    <div className="number animateNumber" data-num="530"> <span>530</span></div>
                    <p> Partenaires</p>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="counter">
                    <div className="counter-icon-box"><i className="lnr lnr-thumbs-up"></i></div>
                    <div className="number animateNumber" data-num="49"> <span>49</span></div>
                    <p> Événements annuels</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
  );
};

export default Counters;
