import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/Images/logo.png';
import '../App.css';

const StickyHeader = ({ toggleMenu, isMenuOpen, renderNavigation }) => {
  return (
    <div className="sticky-header">
      <div className="top-container clearfix">
        <div className="logo pull-left">
          <Link to="/" className="img-responsive">
            <img src={Logo} alt="Expert" title="Expert" />
          </Link>
        </div>
        <div className="right-col pull-right d-flex align-items-center">
          <nav className="main-menu">
            <div className="navbar-header">
              <button 
                type="button" 
                className="navbar-toggle" 
                onClick={toggleMenu}
                aria-label="Toggle navigation"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className={`navbar-collapse collapse clearfix ${isMenuOpen ? 'show' : ''}`}>
              {renderNavigation()}
            </div>
          </nav>
          <Link to="/Adhesion" className="btn btn-primary py-2 px-4 ms-3 theme-btn"
             style={{
              padding: '10px 20px',
              fontSize: '18px',
            }}
          >
            Adhérer
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StickyHeader;
