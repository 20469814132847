import React from 'react';
import aboutImage from '../assets/Images/about-us.png'; // Assurez-vous de mettre le bon chemin d'image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AboutSection = () => {
  return (
    <div className="home-about-wrapper">
    <div className="container">
    <div className="row">
        <div className="col-md-7 about-text">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold  text-uppercase" style={{ fontSize: '3rem', color:'#66bc40'}}>À propos de nous</h5>
            <h1 className="mb-0">Bienvenue à Connect B2B Evolution</h1>
          </div>
        <p className="mb-4" style={{ fontSize: '2rem', textAlign:'justify' }}>
          Connect B2B Evolution est bien plus qu'un simple lieu de réseautage ; c'est un club dynamique d'entrepreneurs déterminés à explorer de nouveaux marchés, à développer leurs activités et à organiser des initiatives innovantes dans le domaine de l'entrepreneuriat et des investissements. Nous avons pour mission de créer un environnement stimulant et collaboratif où chaque membre peut trouver des opportunités significatives et faire progresser ses ambitions professionnelles.
        </p>
        <div className="row g-0 mb-3">
      <div className="col-sm-6 wow animate__animated animate__zoomIn" data-wow-delay="0.2s">
        <h5 className="mb-3" style={{ fontSize: '2rem' }}><FontAwesomeIcon icon={faCheck} className="text-primary me-3" /> Réseautage de Qualité</h5>
        <h5 className="mb-3"style={{ fontSize: '2rem' }}><FontAwesomeIcon icon={faCheck} className="text-primary me-3" /> Opportunités de Collaboration</h5>
      </div>
      <div className="col-sm-6 wow animate__animated animate__zoomIn" data-wow-delay="0.4s">
        <h5 className="mb-3"style={{ fontSize: '2rem' }}><FontAwesomeIcon icon={faCheck} className="text-primary me-3" /> 24/7 Support numériques</h5>
        <h5 className="mb-3"style={{ fontSize: '2rem' }}><FontAwesomeIcon icon={faCheck} className="text-primary me-3" /> Développement des Activités</h5>
      </div>
    </div>
    <Link to='/Apropos' className="btn  py-3 px-5 mt-3 wow animate__animated animate__zoomIn" data-wow-delay="0.9s">En savoir +</Link>
        </div>
        <div className="col-md-5 about-image">
        <img src={aboutImage} alt="" />
        </div>
    </div>
    </div>
</div>
  );
};

export default AboutSection;
